@charset "UTF-8";
.search-box {
  margin-top: 10px;
  margin-right: 50px;
  /*
    @media only screen and (max-width: 600px) { //手機版
        margin-right:0px;
    }
    */ }
  .search-box select {
    background-color: #999999;
    color: white;
    width: 80px;
    height: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    text-align: center;
    text-align-last: center;
    text-align: -webkit-center; }
  .search-box input {
    width: 300px;
    height: 30px;
    color: black;
    /*
        @media only screen and (max-width: 600px) { //手機版
            width: calc(100% - 160px);
        }
        */ }
  .search-box button {
    background-color: #999999;
    color: white;
    margin-left: 10px;
    width: 80px;
    line-height: 17px;
    height: 30px;
    border-radius: 5px;
    /*
        @media only screen and (max-width: 600px) { //手機版
            margin-left:0px;
        }
        */ }
