@import 'base';

//搜尋框  20181016 Rock
.search-box{    //寬度為470px
    margin-top: 10px;
    margin-right: 50px;
    //font-size: 16px !important;
    
    /*
    @media only screen and (max-width: 600px) { //手機版
        margin-right:0px;
    }
    */
    
    select{
        background-color: $standard_gray;
        color: white;
        width: 80px;
        height: $header_item_height;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        text-align: center;    //firefox
        text-align-last:center; //chrome
        text-align: -webkit-center;
    }
    input{
        width: 300px;
        height: $header_item_height;
        color: black;
        /*
        @media only screen and (max-width: 600px) { //手機版
            width: calc(100% - 160px);
        }
        */
    }
    button{
        background-color: $standard_gray;
        color: white;
        margin-left:10px;
        width: 80px;
        line-height: 17px;
        height: $header_item_height;
        border-radius: 5px;
        /*
        @media only screen and (max-width: 600px) { //手機版
            margin-left:0px;
        }
        */
    }
}